<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="body-PB">
    <iframe title="dispensación Savia Salud" width="100%" height="100%"
      src="https://app.powerbi.com/view?r=eyJrIjoiY2Y0OWM5ZjMtOTFiYy00MDg2LTllZWQtMjZjNWQ0MWUyOTQ1IiwidCI6IjllMDQ0ZjE3LWM3M2MtNGU1ZC04NWViLTMyNDNlNTU1YjczOSIsImMiOjR9"
      frameborder="0" allowFullScreen="true"></iframe>
  </div>
</template>
 
 <!-- #################################################################################### -->
 <!-- ###### Sección de Scripts                                                     ###### -->
 <!-- #################################################################################### -->
<script>
export default {
  name: 'DispensacionSavia',
}
</script>
 
 <!-- #################################################################################### -->
 <!-- ###### Sección de styles                                                      ###### -->
 <!-- #################################################################################### -->
<style scoped>
.body-PB {
  height: 93% !important;
}
</style>